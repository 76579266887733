<template>
  <div class="paypal-payment">
    <div class="form-group" v-if="paymentError">
      <div class="error-container payment-error">
        <p class="error">{{ paymentError }}</p>
      </div>
    </div>
    <div id="paypal-button-container" class="paypal-card"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    gatewaydetail: {
      type: Object
    },
    paymentDetail: {
      type: Object
    }
  },
  data() {
    return {
      paypalDetail: null,
      referenceData: null,
      paymentError: null
    };
  },
  computed: {
    ...mapGetters(["appConfig"])
  },
  watch: {
    paymentError(val) {
      if (val) {
        setTimeout(() => {
          this.paymentError = null;
        }, 5000);
      }
    }
  },
  created() {
    console.log("payment detail", this.paymentDetail);
    console.log("gateway detail", this.gatewaydetail);
  },
  mounted() {
    setTimeout(() => {
      this.renderPaypalButton();
    }, 100);
  },
  methods: {
    ...mapActions(["paymentInitiation", "paymentConfirmation"]),
    //paypal payment function.
    renderPaypalButton() {
      let self = this;
      let data = {};

      if (self.gatewaydetail.gwcredential.pgEnv === "sandbox") {
        data.sandbox = self.gatewaydetail.gwcredential.ClientID;
      } else {
        data.production = self.gatewaydetail.gwcredential.ClientID;
      }

      var paypal = window.paypal;

      paypal.Button.render(
        {
          locale: "",
          country: "",
          env:
            self.gatewaydetail.gwcredential.pgEnv === "live" ||
            self.gatewaydetail.gwcredential.pgEnv === "production"
              ? "production"
              : "sandbox",
          client: data,
          commit: true,

          style: {
            size: "responsive",
            color: "blue",
            shape: "rect",
            tagline: false,
            label: "paypal",
            response: true
          },

          funding: {
            allowed: [],
            disallowed: [paypal.FUNDING.CARD, paypal.FUNDING.CREDIT]
          },

          payment: function() {
            self.paymentError = null;
            let payload = {};
            payload.gatewayid = self.gatewaydetail.gatewayid;
            payload.intent = "sale";
            payload.payer = {
              payment_method: "paypal"
            };
            payload.application_context = {
              shipping_preference: "NO_SHIPPING"
            };

            if (self.paymentDetail.transactionPurpose === "SUBSCRIPTION") {
              payload.params = {};
              payload.params.amount = self.paymentDetail.detail.amount;
              payload.params.currency = self.paymentDetail.detail.currency;
              payload.params.devicetype = "WEB";
              payload.params.transactionpurpose = self.paymentDetail.transactionPurpose;
              payload.params.transactionmode = "CC";
              payload.params.availabilityid = self.paymentDetail.detail.availabilityset[0];
              payload.params.planid = self.paymentDetail.detail.planid;
            } else {
              payload.params = {};
              payload.params.amount = self.paymentDetail.detail.amount;
              payload.params.currency = self.paymentDetail.detail.currency;
              payload.params.devicetype = "WEB";
              payload.params.transactionpurpose = self.paymentDetail.transactionPurpose;
              payload.params.transactionmode = "DC";
              payload.params.availabilityid = self.paymentDetail.detail.availabilityid;
              payload.params.objectid = "PURCHASE";
              payload.params.purchasetype = self.paymentDetail.detail.purchasetype;
              payload.params.priceclassid = self.paymentDetail.detail.priceclassid;
            }

            console.log("payload paypal", payload);

            return self
              .paymentInitiation(payload)
              .then(response => {
                if (!response.data.reason) {
                  self.referenceData = response.data;
                  return self.referenceData.referencedata.orderid;
                } else {
                  self.paymentError = response.data.reason;
                }
              })
              .catch(error => {
                if (error.status === 401) {
                  self.paymentError = "Session Expired.Please try again";
                }
              });
          },
          //On Payment Success
          onAuthorize: function(paymentdata) {
            console.log("payment data", paymentdata);

            let payload = {
              gatewayid: self.gatewaydetail.gatewayid,
              referencedata: self.referenceData,
              paymentdata: paymentdata
            };
            self.paymentConfirmationCheck(payload);
            // self.paymentConfirmationCallback(payload);
          },
          //On Payment Cancel.
          onCancel: function() {
            self.paymentError = "Payment Cancelled";
          }
        },
        "#paypal-button-container"
      );
    },
    //paypal payment confirmation event check.
    paymentConfirmationCheck(payload) {
      eventBus.$emit("payment-confirmation", payload);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_components.scss";
@import "./paypal.scss"
</style>
